import Pui9HttpRequests from 'pui9-requests';
// import Pui9Notifications from'pui9-notifications';

export async function signOut() {
	var controller = `/login/signout`;
	let data = [];
	await Pui9HttpRequests.getRequest(
		controller,
		null,
		response => {
			if (response && response.data) {
				data = response.data;
			}
		},
		error => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}

export async function passwordReset(id, email) {
	var controller = `/puiuser/requestPasswordReset?event=${id}&usrEmail=${email}`;
	let data = [];
	await Pui9HttpRequests.getRequest(
		controller,
		null,
		response => {
			if (response && response.data) {
				data = response;
				data.responseType = 'success';
				// Pui9Notifications.success("nfsdf", "sfsodf");
			}
		},
		error => {
			if (error) {
				data = error.response.data.message;
				// this.$puiNotify.error("dfsdf", this.$t('login.error'));
				// Pui9Notifications.error("snfsdhjf", "soifjsdfsj")
			}
		}
	);

	return data;
}

export async function login(body) {
	const controller = '/login/signin';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		body,
		response => {
			if (response && response.data) {
				data = response.data;
				data.responseType = 'success';
			}
		},
		error => {
			if (error) {
				data = error;
				data.responseType = 'error';
			}
		}
	);

	return data;
}

export async function setWorkingRole(role) {
	const controller = '/login/setWorkingRole';
	let data = [];

	await Pui9HttpRequests.getRequest(
		controller,
		{ role: role },
		response => {
			if (response && response.data) {
				data = response.data;
			}
		},
		error => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}

export async function singUp(event, body) {
	const controller = `/uservisitor/registerUserVisit?event=${event}`;
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		body,
		response => {
			if (response && response.data) {
				data = response.data;
				data.responseType = 'success';
			}
		},
		error => {
			if (error) {
				data = error.response;
			}
		}
	);

	return data;
}

export async function changePassword(oldPassword, newPassword) {
	const controller = `/puiuser/changeUserPasswordLoggedUser?newPassword=${newPassword}&oldPassword=${oldPassword}`;
	let data = [];

	await Pui9HttpRequests.getRequest(
		controller,
		null,
		response => {
			if (response) {
				data = response;
			}
		},
		error => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}

export async function resetPassword(body) {
	const controller = '/puiuser/resetPassword';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		body,
		response => {
			data = response;
			data.responseType = 'success';
		},
		error => {
			if (error) {
				data = error;
				data.responseType = 'error';
			}
		}
	);

	return data;
}

export async function validateUser(token) {
	var controller = `/uservisitor/validateUserVisit?validationToken=${token}`;
	let data = [];
	await Pui9HttpRequests.getRequest(
		controller,
		null,
		response => {
			if (response) {
				data = response;
			}
		},
		error => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}

export async function tokenValidation(token) {
	const controller = `/puiuser/tokenValidation?resetToken=${token}`;
	let data = [];

	await Pui9HttpRequests.getRequest(
		controller,

		response => {
			data = response;
		},
		error => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}

export async function checkSession(token) {
	const controller = `/login/checkSession`;
	let data = [];
	const body = {
		jwt: token
	};
	await Pui9HttpRequests.getRequest(
		controller,
		body,
		response => {
			data = response;
		},
		error => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}

export async function getModelConfigurations() {
	const controller = `/puimodel/getModelConfigurations`;
	let data = [];
	await Pui9HttpRequests.getRequest(
		controller,
		{},
		response => {
			data = response;
		},
		error => {
			if (error) {
				data = error.response;
			}
		}
	);

	return data;
}
