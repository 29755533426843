/* eslint-disable */
import store from '../store/store.js';
import { getEventId, getEventInfo, addRelationshipUserEvent } from '../api/event.js';
import { getModelConfigurations } from '../api/sessions.js';
import { getDocument } from '../api/common.js';

export default async function feriaEventHelper() {
	// comprobamos que la sesión que tenemos no ha caducado.
	await checkSession();

	// Obtenemos el nombre del evento y su id
	const eventname = await getEventName();
	const eventid = await getEventNameId(eventname);

	// Obtenemos toda la informacion del evento y su logo y lo guardamos en el store
	const eventInfo = await getEventInfo(eventid);
	const eventLogo = await getEventLogo(eventInfo);

	setEventNameInfo(eventInfo);
	setEventLogo(eventLogo);
	setLanguage();
	setCustomCss(eventInfo.evenwebcss);
	setCustomJs(eventInfo.evenwebpixel);
	// Función para las analíticas. A que eventos acceden los usuarios.
	if (!window.localStorage.getItem('anonymous_session')) {
		await addRelationshipUserEvent(eventInfo.eveneventgroupid);
	}
}

async function checkSession() {
	if (window.localStorage.getItem('anonymous_session') && window.localStorage.getItem('pui9_session')) {
		window.localStorage.removeItem('anonymous_session');
	}

	const data = await getModelConfigurations();

	if (data.status === 401) {
		if (window.localStorage.getItem('pui9_session')) {
			window.localStorage.removeItem('pui9_session');
		} else if (window.localStorage.getItem('anonymous_session')) {
			window.localStorage.removeItem('anonymous_session');
		}
		location.reload();
	}
}

async function getEventName() {
	let currentLocation = window.location.href;
	currentLocation = currentLocation.split('/');

	/*
		El nombre del evento es el que está despues de la base url (que es catalog)
		Si no existe porque han puesto mal la url pues retornamos falso y al final el nombre
		del evento es un string vacío
	*/
	const eventnameIndex = currentLocation.indexOf('catalog') ? currentLocation.indexOf('catalog') + 1 : false;

	return eventnameIndex ? currentLocation[eventnameIndex] : '';
}

async function getEventNameId(eventname) {
	// Si el nombre del evento existe porque han puesto bien la url par adelante si no por defecto el id es -1
	if (eventname !== '') {
		// Funcion getEventId esta en api/event.js
		return getEventId(eventname);
	} else {
		return -1;
	}
}

function setEventNameInfo(eventInfo) {
	store.state.eventInfo = eventInfo;
	store.state.eventInfo.defaultColor = '#D4AF37';
}

function setLanguage() {
	if (window.localStorage.getItem('anonymous_session')) {
		store.state.activeLanguage = window.localStorage.getItem('pui9_lang').toUpperCase();
	} else {
		const session = JSON.parse(window.localStorage.getItem('pui9_session'));
		store.state.activeLanguage = session.language.toUpperCase();
	}
}

async function getEventLogo(eventInfo) {
	const data = await getDocument('event', eventInfo.evenid, 'event_catalog_logo');
	return data[0].url;
}

function setEventLogo(eventLogo) {
	store.state.eventInfo.eventLogo = eventLogo;
}

// ADD CUSTOM EVENT CSS AND JS TO THE APP
function setCustomCss(rule) {
	const css = document.createElement('style');
	css.type = 'text/css';
	// Support for IE
	if (css.styleSheet) css.styleSheet.cssText = rule;
	// Support for the rest
	else css.appendChild(document.createTextNode(rule));
	document.getElementsByTagName('head')[0].appendChild(css);
}
function setCustomJs(rule) {
	const js = document.createElement('script');
	js.type = 'text/javascript';
	js.appendChild(document.createTextNode(rule));
	document.getElementsByTagName('body')[0].appendChild(js);
}
