import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);
const appRoutes = [
	{
		path: ':eventname/home',
		name: 'Home',
		props: true,
		component: () => import('@/views/home/Home.vue'),
		meta: { requiresAuth: false }
	},
	{
		path: ':eventname/login',
		name: 'Login',
		props: true,
		component: () => import('@/views/login/Login.vue'),

		meta: { requiresAuth: false }
	},

	{
		path: ':eventname/signup',
		name: 'SignUp',
		props: true,
		component: () => import('@/views/login/SignUp.vue'),
		meta: { requiresAuth: false }
	},
	{
		path: ':eventname/resetpasswordvalidation',
		name: 'ResetPasswordValidation',
		props: true,
		component: () => import('@/views/login/ResetPasswordValidation.vue'),
		meta: { requiresAuth: false }
	},
	{
		path: ':eventname/validateuservisit',
		name: 'ValidateUserVisit',
		props: true,
		component: () => import('@/views/login/ValidateUserVisit.vue'),
		meta: { requiresAuth: false }
	},
	{
		path: ':eventname/privacy',
		name: 'Privacy',
		props: true,
		component: () => import('@/views/footerviews/Privacy.vue'),
		meta: { requiresAuth: false }
	},
	{
		path: ':eventname/cookies',
		name: 'Cookies',
		props: true,
		component: () => import('@/views/footerviews/Cookies.vue'),
		meta: { requiresAuth: false }
	},
	{
		path: ':eventname/legaladvice',
		name: 'LegalAdvice',
		props: true,
		component: () => import('@/views/footerviews/LegalAdvice.vue'),
		meta: { requiresAuth: false }
	},
	{
		path: ':eventname/resetpassword',
		name: 'ResetPassword',
		props: true,
		component: () => import('@/views/login/ResetPassword.vue'),
		meta: { requiresAuth: false }
	},
	{
		path: ':eventname/appointments',
		name: 'Appointments',
		props: true,
		component: () => import('@/views/appointments/Appointments.vue'),
		meta: { requiresAuth: false }
	},

	{
		path: ':eventname/visits',
		name: 'Visits',
		props: true,
		component: () => import('@/views/visits/UserVisits.vue'),
		meta: { requiresAuth: false }
	},
	{
		path: ':eventname/interests',
		name: 'Interests',
		props: true,
		component: () => import('@/views/interests/Interests.vue'),
		meta: { requiresAuth: false }
	},
	{
		path: ':eventname/activities',
		name: 'Activities',
		props: true,
		component: () => import('@/views/activities/Activities.vue'),
		meta: { requiresAuth: false }
	},
	{
		path: ':eventname/exhibitors',
		name: 'Exhibitors',
		props: true,
		component: () => import('@/views/exhibitors/Exhibitors.vue'),
		meta: { requiresAuth: false }
	},
	{
		path: ':eventname/exhibitors/:id',
		name: 'ExhibitorDetails',
		props: true,
		component: () => import('@/views/exhibitors/ExhibitorDetails.vue'),
		meta: { requiresAuth: false }
	},
	{
		path: ':eventname/products',
		name: 'Products',
		props: true,
		component: () => import('@/views/products/Products.vue'),
		meta: { requiresAuth: false }
	},
	{
		path: ':eventname/products/:id',
		name: 'ProductDetails',
		props: true,
		component: () => import('@/views/products/ProductDetails.vue'),
		meta: { requiresAuth: false }
	},

	{
		path: ':eventname/activities/:id',
		name: 'ActivitiesDetails',
		props: true,
		component: () => import('@/views/activities/ActivitiesDetails.vue'),
		meta: { requiresAuth: false }
	},
	{
		path: ':eventname/my-account',
		name: 'MyAccount',
		props: true,
		component: () => import('@/views/myAccount/MyAccount.vue'),
		meta: { requiresAuth: false }
	},
	{
		path: ':eventname/contact',
		name: 'Contact',
		props: true,
		component: () => import('@/views/contact/Contact.vue'),
		meta: { requiresAuth: false }
	},
	{
		path: ':eventname/privacysetting',
		name: 'PrivacySetting',
		props: true,
		component: () => import('@/views/PrivacySetting/PrivacySetting.vue'),
		meta: { requiresAuth: false }
	}
];

const puiNotFound = [{ path: '*', component: () => import('pui9-base/src/components/PuiNotFound') }];

const mergedRouter = {
	mode: 'history',
	hash: false,
	base: '/catalog',
	routes: [
		{
			path: '/login',
			component: () => import('@/views/home/Home.vue')
		},
		{
			path: `/`,
			component: () => import('@/layout'),
			children: appRoutes.concat(puiNotFound)
		}
	]
};

export default new VueRouter(mergedRouter);
