import Pui9HttpRequests from 'pui9-requests';

export async function getEventId(eventname) {
	var controller = `/eventgroup/getIdFromUrl?url=${eventname}`;
	let data = [];
	await Pui9HttpRequests.getRequest(
		controller,
		null,
		response => {
			if (response && response.data) {
				data = response.data;
			}
		},
		error => {
			if (error) {
				data = -1;
			}
		}
	);

	return data;
}

export async function getEventInfo(id) {
	var controller = `/event/get?evenid=${id}`;
	let data = [];

	await Pui9HttpRequests.getRequest(
		controller,
		null,
		response => {
			if (response && response.data) {
				data = response.data;
			}
		},
		error => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}

export async function addRelationshipUserEvent(id) {
	var controller = `/eventgroup/addRelationshipUserEvent`;
	let data = [];
	const body = {
		evgrid: id
	};

	await Pui9HttpRequests.getRequest(
		controller,
		body,
		response => {
			if (response && response.data) {
				data = response.data;
			}
		},
		error => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}
