import Pui9HttpRequests from 'pui9-requests';

export async function getDocument(model, pk, role) {
	var controller = `/puidocument/getDocuments?model=${model}&pk=${pk}&role=${role}`;
	let data = [];
	await Pui9HttpRequests.getRequest(
		controller,
		null,
		(response) => {
			if (response && response.data) {
				data = response.data;
			}
		},
		(error) => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}

export async function search(body) {
	const controller = '/puisearch';
	let data = [];
	await Pui9HttpRequests.postRequest(
		controller,
		body,
		(response) => {
			if (response && response.data) {
				data = response.data;
			}
		},
		(error) => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}

export async function random(body) {
	const controller = '/puisearch';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		body,
		(response) => {
			if (response && response.data) {
				for (let i = 0; i < 5; i++) {
					if (response.data.data.length > i) {
						var number = Math.floor(Math.random() * response.data.data.length);
						console.log(number);
						data.push(response.data.data[number]);
					}
				}
			} else {
				data = null;
			}
		},
		(error) => {
			if (error) {
				data = error;
				console.log(data);
				//this.$puiNotify.error(message, 'Error');
			}
		}
	);

	return data;
}

export function getUserEventRelationship({ usr }) {
	const controller = '/relationship/userevent';
	const params = { usr };

	return new Promise((resolve, reject) => {
		Pui9HttpRequests.getRequest(
			controller,
			params,
			(response) => resolve(response),
			(error) => reject(error)
		);
	});
}

export function saveUserEventRelationShip(body) {
	const controller = '/relationship/userevent';

	return new Promise((resolve, reject) => {
		Pui9HttpRequests.postRequest(
			controller,
			body,
			(response) => resolve(response),
			(error) => reject(error)
		);
	});
}

export function getUserRelationShip({ params }) {
	const controller = '/relationship/userrelationship';

	return new Promise((resolve, reject) => {
		Pui9HttpRequests.getRequest(
			controller,
			params,
			(response) => resolve(response),
			(error) => reject(error)
		);
	});
}

export function updateUserReceiveInformation(body) {
	const controller = '/relationship/userreceiveinformation';

	return new Promise((resolve, reject) => {
		Pui9HttpRequests.postRequest(
			controller,
			body,
			(response) => resolve(response),
			(error) => reject(error)
		);
	});
}

export function saveLogsAccess(body) {
	const controller = '/logsaccess/insert';

	return new Promise((resolve, reject) => {
		Pui9HttpRequests.postRequest(
			controller,
			body,
			(response) => resolve(response),
			(error) => reject(error)
		);
	});
}

export function receiveDateNow() {
    const dateNow = new Date();

    const year = dateNow.getFullYear();
    const month = String(dateNow.getMonth() + 1).padStart(2, '0');
    const day = String(dateNow.getDate()).padStart(2, '0');
    const hours = String(dateNow.getHours()).padStart(2, '0');
    const minutes = String(dateNow.getMinutes()).padStart(2, '0');
    const seconds = String(dateNow.getSeconds()).padStart(2, '0');

    const formatedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.000`;

    return formatedDate;
}