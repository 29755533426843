export default {
	global: {
		appVersion: '1.0.0',
		appDefaultEntryPoint: 'home',
		baseUrl: '/api',
		withLogin: true
	},
	menu: {
		keepExpanded: true
	},
	eventInfo: {},
	activeLanguage: '',
	loginData: {}
};
